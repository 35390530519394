import React from "react"

import Layout from "../components/layout"
import Subscribe from "../components/subscribe"
import SEO from "../components/seo"

class SubscribePage extends React.Component {
  render() {
    return (
      <Layout hideFooter location={this.props.location} title={"Subscribe"}>
        <SEO title="Peter Elbaum | Subscribe" />
        <h1>Subscribe</h1>
        <Subscribe />
      </Layout>
    )
  }
}

export default SubscribePage
